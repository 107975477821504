import React, { Component } from 'react';
import './Imageviewer.css';

import { connect } from 'react-redux';
import { despatchAction, ACTION_TYPES } from '../../redux/actions';

import HtmlElement from '../../lib/lem-element';
import Interact from '../../lib/lem-interact';
import CropArea from './CropArea';

var _ = require('lodash');

class CropOverlay extends Component {

    state = {
        nodeNum: 1,
        topLeft: null,
        topRight: null,
        bottomRight: null,
        bottomLeft: null
    }

    componentDidMount = () => {
        Interact.newNode(this.captureNodeCoords)
        .then(response => {
            console.log(response)
        })
        .catch(err => {
            console.log(err)
        })
    }

    captureNodeCoords = (element, rect) => {
        this.props.cropperNodes[element.id].coords = rect;
        this.props.despatchAction(ACTION_TYPES.cropperNodeHandler, {"cropperNodes": Object.assign({}, this.props.cropperNodes)})
    }

    addClipNode = (event) => {
        let cropContainer = document.getElementById('crop-container');
        let imageView = document.getElementById('img-preview')
        let elementInfo = imageView.getBoundingClientRect()
        let imgElement = this.props.imgElement;
        let nodeNum = this.state.nodeNum;
        let yOffset = window.pageYOffset;

        console.log('IMG ELEMENTS: ', imgElement)
        console.log('ELEMENT INFO Y ', elementInfo.y)
        console.log('PAGE WINDOW OFFSET: ', yOffset)
        console.log('WINDOW SCROLL Y: ', window.scrollY)
        console.log('CLIENT Y: ', event.clientY)
        console.log('PAGE Y: ', event.pageY)

        imgElement.elementY = elementInfo.y

        let coords = {
            "coords": {
                "left": event.clientX,
                "top": event.clientY
            },
            "elementY": imgElement.elementY
        }

        console.log('Coords: ', coords)

        if (event.target.id === 'crop-container') {
            HtmlElement.newNodeElement(event, nodeNum, imgElement, coords)
            .then(response => {
                this.props.cropperNodes[`node-${nodeNum}`] = coords;
                console.log('CROP NODES: ', this.props.cropperNodes)
                this.props.despatchAction(ACTION_TYPES.cropperNodeHandler, {"cropperNodes": Object.assign({}, this.props.cropperNodes)})
                cropContainer.appendChild(response)
                this.setState({
                    nodeNum: nodeNum += 1
                })
            })
            .catch(err => {
                console.log(err)
            })
        }
        
        console.log('New Node Created')
    }

    addCropArea = () => {
        let nodes = this.props.cropperNodes

        const mathNodes = (nodes, operation) => {
            let nodeArr = []
            Object.entries(nodes).map(node => {
                let coordsOperation
                if (operation === 'sum'){
                    coordsOperation = node[1].left + node[1].top
                } else if (operation === 'diff') {
                    coordsOperation = node[1].left - node[1].top
                }
                
                let nodePair = []
                nodePair.push(node[0], coordsOperation)
                nodeArr.push(nodePair)
                return 'completed'
            })
            return nodeArr;
        }
        
        const minNode = (sum) => {
            let minNode = _.minBy(sum, function(node) { return node[1] })
            return minNode;
        }
        
        const maxNode = (sum) => {
            let maxNode = _.maxBy(sum, function(node) { return node[1] })
            return maxNode;
        }

        const sum = mathNodes(nodes, 'sum')
        const diff = mathNodes(nodes, 'diff')

        console.log('SUM: ', sum)
        console.log('DIFF: ', diff)

        if (sum.length > 3 && diff.length > 3) {
            const topLeftNode = minNode(sum)
            const topRightNode = maxNode(diff)
            const bottomRightNode = maxNode(sum)
            const bottomLeftNode = minNode(diff)

            return(
                <CropArea 
                    topLeft={topLeftNode}
                    topRight={topRightNode}
                    bottomRight={bottomRightNode}
                    bottomLeft={bottomLeftNode}
                />
            )
        }
    }
    
    render() {
        let nodes = this.props.cropperNodes
        let cropOverlay;
        let imgElement = this.props.imgElement
        console.log(Object.keys(nodes).length)

        if ( Object.keys(nodes).length > 3 ) {
            cropOverlay = <div id='crop-container' className='crop-overlay' style={{ width: imgElement.elementWidth, height: imgElement.elementHeight }} >
                    
            </div>
        } else {
            cropOverlay = <div id='crop-container' className='crop-overlay' style={{ width: imgElement.elementWidth, height: imgElement.elementHeight }} onClick={this.addClipNode}>
                    
            </div>
        }

        return(
            <div>
                { cropOverlay }
                {/* { this.addCropArea() } */}
            </div>
        )
    }
}

const mapStateToProps = state => { return {
    imgElement: state.imgElement,
    cropperNodes: state.cropperNodes
} };
export default connect(mapStateToProps, { despatchAction })(CropOverlay)