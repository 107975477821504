import axios from "axios"
import axiosRetry from 'axios-retry';

import CONFIG from './config';

let Module = {}

Module.fetchJson = (url) => {
    return new Promise((resolve, reject) => {
        fetch(url, {
            headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => resolve(data))
        .catch(error => reject(error))
    })
}

Module.getCountry = (countryCode) => {
    return new Promise((resolve, reject) => {
        let url = `https://restcountries.eu/rest/v2/alpha/${countryCode}`
        axios.get(url)
        .then(response => resolve(response.data.name))
        .catch(error => reject(error))
    })
}

Module.getTemplates = ( payload ) => {
    return new Promise((resolve, reject) => {
        const headers = {
            headers: {
                'Authorization': payload.token,
                "Content-Type": 'application/json'
            }
        }

        let url = `${ CONFIG.baseURL }/templates`

        axios.get(url, headers)
        .then(response => resolve(response.data))
        .catch(error => {
            alert('Token is not valid or has expired to get templates')
            reject(error)
        })
    })
}

Module.putTemplateBack = ( payload, templates ) => {
    return new Promise((resolve, reject) => {
        const headers = {
            headers: {
                'Authorization': payload.token,
                "Content-Type": 'application/json'
            }
        }

        let url = `${ CONFIG.baseURL }/templates`

        axios.put(url, templates, headers)
        .then(response => resolve(response.data))
        .catch(error => {
            alert('Cannot put template back...')
            reject(error)
        })
    })
}

Module.getCroppedSignedUrl = (payload, templateId, side) => {
    return new Promise((resolve, reject) => {
        const headers = {
            headers: {
                'Authorization': payload.token,
                "Content-Type": 'application/json'
            }
        }

        let url = `${ CONFIG.baseURL }/templates/signedurl/${templateId}/${side}`;

        axios.get(url, headers)
        .then(response => resolve(response.data))
        .catch(error => {
            alert('SessionId or Token is not valid or has expired to get CropperSignedUrl')
            // window.localStorage.clear()
            // window.location.reload()
        })
        .catch(error => reject(error))
    })
}

Module.getSignedUrl = (payload) => {
    return new Promise((resolve, reject) => {
        const headers = {
            headers: {
                'Authorization': payload.token,
                "Content-Type": 'application/json'
            }
        }

        let url = `${ CONFIG.baseURL }/verifications/${ payload.sessionId }/images`;
        // let url = `https://api.uat.lemverify.io/internal/v1/verifications/${payload.sessionId}/images`;
        // let url = `https://api.uat.lemverify.io/internal/v1/verifications/0c4842a0-5ccf-11eb-bb80-9d683e77e9f2/images`;
        // axios.get(url)
        axios.get(url, headers)
        .then(response => resolve(response.data))
        .catch(error => {
            alert('SessionId or Token is not valid or has expired')
            window.localStorage.clear()
            window.location.reload()
        })
        .catch(error => reject(error))
    })
}

Module.sendCropperNodes = ( payload ) => {
    return new Promise((resolve, reject) => {
        // let url = `${ CONFIG.cropperURL }/image/processing/crop`
        let url = `https://imageprocessing.uat.lemverify.io/api/image/processing/crop`

        axios.post(url, payload)
        .then(response => resolve(response.data) )
        .catch(error => {
            alert('Cannot send cropping nodes')
            reject(error)
        })
    })
}

Module.sendFile = (url, payload, onUploadProgress) => {

    return new Promise((resolve, reject) => {

        var http = axios.create({
            timeout: 60000,
            headers: {
                'Content-Type': 'image/jpeg'
            }
        });

        // const headers = {
        //     headers: {
        //         "Content-Type": 'image/jpeg'
        //     }
        // }

        // const body = {
        //     data: payload
        // }

        // // axiosRetry(http, { retries: 3, retryDelay: (() => { return 2000 }) });

        // axios.post(url, body, headers)
        http({ method: 'put', url, data: payload, onUploadProgress })
        .then((resp) => {
            resolve(resp);
        })
        .catch((err) => {
            reject(err);
        });

        // fetch(url, {
        //     method: 'PUT',
        //     body: payload,
        //     headers: {
        //         'Content-Type': 'image/jpeg',
        //     }
        // })

    });

}

export default Module;