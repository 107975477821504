import React, { Component } from 'react';
import {Button, Accordion, Col, Row, ListGroup } from 'react-bootstrap';

import { connect } from 'react-redux';
import { despatchAction, ACTION_TYPES } from '../../redux/actions';

import LoadingSpinner from '../Spinner';

import Interact from '../../lib/lem-interact';
import LEM_API from '../../lib/lem-api';

import './index.css'

class Doccontainer extends Component {

    state = {
        documentType: null,
        country: null,
        template: null,
        startTemplating: false,
        countryCodeList: [],
        countryCodeCounter: 0,
        isLoadingAPI: false,
        roiCreateCounter: -1,
        roiIndex: null,
    }

    documentTypeSelectHandler = (documentType) => {
        this.setState({documentType, countryCodeCounter: 0})
        this.countryHandler(documentType);
    }

    countrySelectHandler = (country) => {
        this.setState({country})
    }

    templateSelectHandler = (template) => {
        console.log('SELETECTED TEMPLATE: ', template)
        this.addAllRoiHandler(template) 
    }

    addAllRoiHandler = (template) => {
        const documentType = this.state.documentType;
        const country = this.state.country;

        let templateObj = {
            type: documentType,
            country,
            template
        }

        this.props.despatchAction(ACTION_TYPES.activeTemplate, {"activeTemplate": Object.assign({}, templateObj)})


        console.log(documentType, country)

        let jsonObj = this.props.jsondata[documentType][country][template];
        console.log('JSON OBJECT: ', jsonObj)

        let fielddata = this.props.fielddata;
        let img
        let S3img = this.props.selectedS3File
        let activeSide = this.props.activeSide;
        let imgMeta = this.props.imgElement

        if ( S3img ) {
            img = S3img[activeSide]
        } else {
            img = this.props.selectedFile;
        }

        let widthScaleFactor = img.imgWidth / imgMeta.elementWidth;
        let heightScaleFactor = img.imgHeight / imgMeta.elementHeight;

        const drawElements = () => {
            var boxElements = {} // {}
            console.log('[INFO] Lets start Templating....')
            jsonObj.map(( roi, index) => {
                this.setState({roiIndex: index})
                let x1 = roi.x1;
                let y1 = roi.y1;
                let x2 = roi.x2;
                let y2 = roi.y2;
    
                let leftValue = x1 / widthScaleFactor;
                let topValue = y1 / heightScaleFactor;
    
                let rightValue = x2 / widthScaleFactor;
                let bottomValue = y2 / heightScaleFactor;
    
                let boxWidth = rightValue - leftValue;
                let boxHeight = bottomValue - topValue;

                let metadata;

                if ( roi.metadata ) {
                    metadata = roi.metadata.map( (data, index) => {
                        let entries = Object.entries(data)
                        let name = entries[0][0];
                        return {
                            name,
                            index,
                            check: true,
                            metadata: data
                        }
                    })
                } else {
                    metadata = null;
                }
                    
                let roiData = {
                    boxName: roi.name,
                    metadataCheck: metadata,
                    widthScaleFactor,
                    heightScaleFactor,
                    type: roi.type,
                    roiValues: {
                        topValue,
                        leftValue,
                        rightValue,
                        bottomValue,
                        boxHeight,
                        boxWidth
                    },
                    imgData: {
                        imgWidth: img.imgWidth,
                        imgHeight: img.imgHeight,
                        canvasWidth: imgMeta.elementWidth,
                        canvasHeight: imgMeta.elementHeight
                    }
                }
    
                return Interact.newROI(null, roiData, this.props.boxMovedCallback, imgMeta)
                .then(response => {
                    let roiCreateCounter = this.state.roiCreateCounter
                    this.setState({roiCreateCounter: roiCreateCounter += 1})
                    boxElements[response.roiName] = response
                    this.props.despatchAction(ACTION_TYPES.boxElementsHandler, {"boxElementsArray": Object.assign({}, boxElements)})
                    this.props.updateFielddataCallback(fielddata, response.roiName)
                    // console.log('ROI Counter, ROI index: ', this.state)
                    if ( this.state.roiCreateCounter === this.state.roiIndex ) {
                        console.log('[INFO] Draw ROIs finished....')
                        this.setState({startTemplating: false})
                    }
                })
                .catch(err => {
                    console.log(err)
                })
            })
        }

        let boxElementsArray = this.props.boxElementsArray;

        console.log('Image Width: ', img.imgWidth)
        console.log('Image Height: ', img.imgHeight)

        console.log('Element Width: ', imgMeta.elementWidth)
        console.log('Element Height: ', imgMeta.elementHeight)

        console.log('WidthScaleFac: ', widthScaleFactor)
        console.log('HeightScaleFac: ', heightScaleFactor)

        if ( Object.keys(boxElementsArray).length > 0 ) {
            console.log('BOX ARRAY is greater than 0')

            this.setState({startTemplating: false}, () => {
                this.clearRois()
                this.setState({startTemplating: true}, () => {
                    drawElements()
                })
            })
        } else {
            console.log('BOX ARRAY === 0')
            this.setState({startTemplating: true}, () => {
                drawElements()
            })
        }
    }

    clearRois = () => {
        this.setState({startTemplating: false, roiCreateCounter: -1, roiIndex: null})
        console.log('STATE: ', this.state)
        document.querySelectorAll('.rectangle').forEach( element => {
            console.log('removing elements......')
            element.remove()
        })
        this.props.despatchAction(ACTION_TYPES.activeTemplate, {"activeTemplate": Object.assign({}, {})})
        this.props.despatchAction(ACTION_TYPES.boxElementsHandler, {"boxElementsArray": Object.assign({}, {})})
        this.props.despatchAction(ACTION_TYPES.selectedFielddataHandler, {"selectedFielddata" : Object.assign({}, {})})
    }

    countryHandler = (documentType) => {
        let jsondata = this.props.jsondata
        let countries = [];

        this.setState({ isLoadingAPI: true }, async () => {
            if (documentType) {
                
                Object.keys(jsondata[documentType]).map(async country => {
                    let final_response;
                    try {
                        this.setState({countryCodeCounter: this.state.countryCodeCounter += 1})
                        const response = await LEM_API.getCountry(country)
                        
                        final_response = {
                            Country: response,
                            Code: country
                        }
                        console.log('Country: ', final_response)
                        countries.push(final_response);
        
                        this.setState({
                            countryCodeList: countries
                        })

                        if ( countries.length === this.state.countryCodeCounter && countries.length > 0 ) {
                            this.setState({ isLoadingAPI: false })
                        }
                        
                        return countries
                    } catch (error) {
                        console.log(error)
                        alert(`[WARNING] - ${country} is not a valid IBAN country code, please check and change in master template`)
                        final_response = {
                            Country: country,
                            Code: country
                        }
                        console.log('[ERROR] Country: ', final_response)
                        countries.push(final_response);
        
                        this.setState({
                            countryCodeList: countries
                        })
                    }
                })
            }
        })
    }

    render() {
        const jsondata = this.props.jsondata
        const documentType = this.state.documentType;
        let accordionData

        if ( jsondata ) {
            accordionData = Object.entries(jsondata).map( (documentTypes, docTypeIndex) => (
                <div className="doc-type-card" key={docTypeIndex}>
                    <div className="doc-type-card-header">
                        <Accordion.Toggle as={Button} variant="Light" className="accordion-btn" eventKey={docTypeIndex} onClick={() => this.documentTypeSelectHandler(documentTypes[0])}>
                            <Row>
                                <Col xs={10}>
                                    { documentTypes[0] }
                                </Col>
                                <Col xs={2}>
                                    <img src="/arrow_down.png" alt="arrow down" className="arrow" />
                                </Col>
                            </Row>
                        </Accordion.Toggle>
                    </div>
                    <Accordion.Collapse eventKey={docTypeIndex} >
                        { this.state.isLoadingAPI ?
                            <LoadingSpinner 
                                Message="Loading Country List..."
                            />
                        :
                            <div>
                                <Accordion>
                                    { this.state.documentType ?

                                        this.state.countryCodeList.sort( (a, b) => {
                                            return b.Country < a.Country ? 1
                                            : b.Country > a.Country ? -1
                                            : 0;
                                        }).map((country, countriesIndex) => (
                                            <div className="countries-card" key={countriesIndex} style={{width: '80%', margin: 'auto'}}>
                                                <div className="countries-card-header">
                                                    <Accordion.Toggle as={Button} variant="Light" className="accordion-btn" eventKey={countriesIndex} onClick={() => this.countrySelectHandler(country.Code)} >
                                                        <Row>
                                                            <Col xs={10}>
                                                                {country.Country} - {country.Code}
                                                            </Col>
                                                            <Col xs={2}>
                                                                <img src="/arrow_down.png" alt="arrow down" className="arrow" />
                                                            </Col>
                                                        </Row>
                                                    </Accordion.Toggle>
                                                </div>
                                                <Accordion.Collapse eventKey={countriesIndex}>
                                                    <div style={{width: '80%', margin: 'auto'}}>
                                                        { Object.entries(jsondata[documentType][country.Code]).map( (template, templateIndex) => (
                                                            <div className="template-card" key={templateIndex} >
                                                                <div className="template-card-header">
                                                                    <ListGroup>
                                                                        <ListGroup.Item onClick={() => this.templateSelectHandler(template[0])}>
                                                                            <Row>
                                                                                <Col xs={9}>
                                                                                    { template[0] }
                                                                                </Col>
                                                                                <Col xs={3}>
                                                                                    <img src="/arrow_right.png" alt="arrow right" className="arrow" />
                                                                                </Col>
                                                                            </Row>
                                                                        </ListGroup.Item>
                                                                    </ListGroup>
                                                                </div>
                                                            </div>
                                                        ))}

                                                        { Object.keys(this.props.boxElementsArray).length > 0 ?
                                                            <Button variant="primary" onClick={this.clearRois}>Clear Selected ROIs</Button>
                                                        :
                                                            ""
                                                        }
                                                    </div>
                                                </Accordion.Collapse>

                                            </div>
                                        ))
                                    :
                                        ''
                                    }
                                </Accordion>
                            </div>
                        }
                    </Accordion.Collapse>
                </div>
            ))
        }
        
        return(
            <div>
                <Accordion >
                    { accordionData }
                </Accordion>
            </div>
        )
    }
}

const mapStateToProps = state => { return {
    jsondata: state.jsondata,
    selectedFile: state.selectedFile,
    fielddata: state.fielddata,
    imgElement: state.imgElement,
    boxElementsArray: state.boxElementsArray,
    selectedFielddata: state.selectedFielddata,
    activeSide: state.activeSide,
    selectedS3File: state.selectedS3File,
    boxName: state.boxName
} };
export default connect(mapStateToProps, { despatchAction })(Doccontainer)