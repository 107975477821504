import React, { Component } from 'react';
import './Imageviewer.css';

import { connect } from 'react-redux';
import { despatchAction, ACTION_TYPES } from '../../redux/actions';
import CropOverlay from './CropOverlay';

class Imageviewer extends Component {

    constructor(props){
        super(props);
        this.imageRef = React.createRef(); // create react ref
    }

    imageInfo = () => {
        let element = this.imageRef.current;
        let elementWidth = element.clientWidth;
        let elementHeight = element.clientHeight;
        let elementInfo = element.getBoundingClientRect()
        let elementX = elementInfo.x
        let elementY = elementInfo.y
        
        console.log('Top: ', elementInfo.y)
        console.log('Left: ', elementInfo.x)
        console.log('CLIENT WIDTH: ', element.clientWidth)
        console.log('CLIENT HEIGHT: ', element.clientHeight)
        console.log('element WIDTH: ', element.width)
        console.log('element HEIGHT: ', element.height)

        if (element) {
            this.props.despatchAction(ACTION_TYPES.imgMetaInfo, {imgElement: {elementWidth, elementHeight, elementX, elementY}})
        }
    }

    componentDidMount = () => {
        this.imageInfo()
    }

    componentDidUpdate = () => {
        console.log('RELOADING IMAGE.......')
        this.imageInfo()
    }

    render() {
        let displayCropperOverlay = this.props.displayCropperOverlay;
        console.log('DISPLAY OVERLAY: ', displayCropperOverlay)

        let overlay;

        if ( displayCropperOverlay ) {
            overlay = <CropOverlay />
        } 

        return(
            <div id='image-container'>
                <div id='Imageviewer'>
                    {/* { displayCropperOverlay ?
                        <CropOverlay />
                    :
                        ''
                    } */}
                    { overlay }
                    {/* <img ref={this.imageRef} id="img-preview" src={ this.props.selectedFile } alt="Input File"  height='500vh' /> */}
                    {/* <img ref={this.imageRef} id="img-preview" src={ this.props.selectedFile } alt="Input File" width='721px' /> */}
                    <img ref={this.imageRef} id="img-preview" src={ this.props.selectedFile } alt="Input File" width='100%' />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => { return {
    cropperNodes: state.cropperNodes,
    displayCropperOverlay: state.displayCropperOverlay
} };
export default connect(mapStateToProps, { despatchAction })(Imageviewer)