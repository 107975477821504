import React, { Component } from 'react';
import { Nav } from 'react-bootstrap';

import { connect } from 'react-redux';
import { despatchAction } from '../../redux/actions';
import './index.css';


class Navigation extends Component {
    render() {
        return(
            <Nav className="mr-auto align-items-center justify-content-center navigation" variant="tabs" defaultActiveKey="/">
                <Nav.Link href="/">Templating</Nav.Link>
                {/* <Nav.Link href="#features">Other Tool</Nav.Link>
                <Nav.Link href="#pricing">Other Tool 2</Nav.Link> */}
            </Nav>
        )
    }
}

const mapStateToProps = state => { return {} };
export default connect(mapStateToProps, { despatchAction })(Navigation)