let Module = {}

/**
 * 
 * @param {Object} roi 
 *
    coords: {
        bottom: 124.77496337890625,
        height: 50,
        left: 217.39999389648438,
        right: 417.3999938964844,
        top: 74.77496337890625,
        width: 200
    }
    
 */

Module.toTrue = (roi, imgElement) => {
    return new Promise(( resolve, reject ) => {
        try {
            let coords = roi.coords
            let widthScale = roi.widthScaleFactor
            let heightScale = roi.heightScaleFactor
            let roiName = roi.roiName
            let metadataInput = roi.metadataInput;
            let type = roi.BoxType
            let metadata;

            if (metadataInput) {
                if (metadataInput.length > 0) {

                    metadata = [];
                    metadataInput.map( input => {
                        return metadata.push(input.metadata)
                    })
                }
            }

            if ( roi.interact ) {
                coords.left -= imgElement.elementX
                coords.right -= imgElement.elementX
                coords.top -= imgElement.elementY
                coords.bottom -= imgElement.elementY
            }
            
            // coords.left -= 15
            // coords.right -= 15
            coords.left *= widthScale
            coords.right *= widthScale
            coords.top *= heightScale
            coords.bottom *= heightScale

            let json = {
                name: roiName,
                type,
                x1: Math.floor(coords.left),
                y1: Math.floor(coords.top),
                x2: Math.floor(coords.right),
                y2: Math.floor(coords.bottom),
                metadata
            }

            resolve(json)
        } catch (err) {
            reject(err)
        }
    })
}

export default Module;