import React, { Component } from 'react';
// import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';

import Navigation from './Navigation'
import Session from './LandingPage/Session';
import Authenticate from './Authenticate'
import Version from './Version'

class App extends Component {
    render() {
        return (
            <div className="App">
                <Session />
                <Navigation />
                <Authenticate />
                <Version />
            </div>
        );
    }
    
}

export default App;
// export default withAuthenticator(App);
