const prod = {
    baseURL: "https://api.lemverify.io/internal/v1",
    cropperURL: "https://imageprocessing.uat.lemverify.io/api"
}

const uat = {
    baseURL: "https://api.uat.lemverify.io/internal/v1",
    cropperURL: "https://imageprocessing.uat.lemverify.io/api"
}

//once we have a true dev env - this can become it's own object
const dev = Object.assign({}, uat);

const config = (stage) => {
	switch (stage) {
		case 'prod':
			return prod;
		case 'uat':
			return uat;
		default:
			return dev;
	}
}

const options = {
	stage: process.env.REACT_APP_STAGE,
	...config(process.env.REACT_APP_STAGE)
}

export default options;