import interact from 'interactjs';

let Module = {};

Module.newROI = (imageCanvas, data, callback, imgElement) => {
    return new Promise((resolve, reject) => {
        try {
            let widthScaleFactor = data.widthScaleFactor
            let heightScaleFactor = data.heightScaleFactor;
            let metadataCheck = data.metadataCheck
            let coords = {}

            if (data.roiValues) {
                coords.left = data.roiValues.leftValue
                coords.right = data.roiValues.rightValue
                coords.top = data.roiValues.topValue
                coords.bottom = data.roiValues.bottomValue
                coords.width = data.roiValues.boxWidth
                coords.height = data.roiValues.boxHeight
            }

            interact('.rectangle')
            .resizable({
                // resize from all edges and corners
                edges: { left: false, right: true, bottom: true, top: false },

                listeners: {
                move (event) {
                    var target = event.target
                    target.innerText = ''
                    callback(target, event.rect);
                    var x = (parseFloat(target.getAttribute('data-x')) || 0)
                    var y = (parseFloat(target.getAttribute('data-y')) || 0)

                    // update the element's style
                    target.style.width = event.rect.width + 'px'
                    target.style.height = event.rect.height + 'px'

                    // translate when resizing from top or left edges
                    x += event.deltaRect.left
                    y += event.deltaRect.top

                    target.style.webkitTransform = target.style.transform =
                    'translate(' + x + 'px,' + y + 'px)'

                    target.setAttribute('data-x', x)
                    target.setAttribute('data-y', y)
                    target.textContent = Math.round(event.rect.width) + '\u00D7' + Math.round(event.rect.height)
                    target.innerText = ''
                }
                },
                modifiers: [
                // keep the edges inside the parent
                interact.modifiers.restrictEdges({
                    outer: 'parent'
                }),

                // minimum size
                interact.modifiers.restrictSize({
                    min: { width: 20, height: 10 }
                })
                ],

                inertia: true
            })
            .draggable({
                listeners: { move: dragMoveListener },
                inertia: true,
                modifiers: [
                interact.modifiers.restrictRect({
                    restriction: 'parent',
                    endOnly: true
                })
                ]
            })

            function dragMoveListener (event) {
                var target = event.target
                // keep the dragged position in the data-x/data-y attributes
                var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
                var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy
            
                // translate the element
                target.style.webkitTransform =
                target.style.transform =
                    'translate(' + x + 'px, ' + y + 'px)'
            
                // update the posiion attributes
                target.setAttribute('data-x', x)
                target.setAttribute('data-y', y)
                target.innerText =''
                callback(target, event.rect);
            }

            let metadataInput = metadataCheck;

            let boxElements = {
                roiName: data.boxName,
                BoxType: data.type,
                metadataInput,
                widthScaleFactor,
                heightScaleFactor,
                coords,
                imgData: data.imgData
            };

            resolve(boxElements)
        } catch(err) {
            reject(err)
        }
    })
}

Module.newNode = (callback) => {
    return new Promise((resolve, reject) => {
        try {
            interact('.clip_node')
            .draggable({
                listeners: { move: dragMoveListener },
                inertia: true,
                modifiers: [
                interact.modifiers.restrictRect({
                    restriction: 'parent',
                    endOnly: true
                })
                ]
            })

            function dragMoveListener (event) {
                var target = event.target
                // keep the dragged position in the data-x/data-y attributes
                var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
                var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy
            
                // translate the element
                target.style.webkitTransform =
                target.style.transform =
                    'translate(' + x + 'px, ' + y + 'px)'
            
                // update the posiion attributes
                target.setAttribute('data-x', x)
                target.setAttribute('data-y', y)
                
                callback(target, event.rect);
            }
            resolve('Nodes are live')
        } catch(err) {
            reject(err)
        }
    })
}

Module.activateModalDragger = (className) => {
    return new Promise((resolve, reject) => {
        try {
            interact(className)
            .draggable({
                listeners: { move: dragMoveListener },
                inertia: true,
                modifiers: [
                    interact.modifiers.restrictRect({
                        endOnly: true
                    })
                ]
            })

            function dragMoveListener (event) {
                var target = event.target
                // keep the dragged position in the data-x/data-y attributes
                var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
                var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy
            
                // translate the element
                target.style.webkitTransform =
                target.style.transform =
                    'translate(' + x + 'px, ' + y + 'px)'
            
                // update the posiion attributes
                target.setAttribute('data-x', x)
                target.setAttribute('data-y', y)
            }
            resolve('Modal is Moving')
        } catch(err) {
            reject(err)
        }
    })
}

Module.deactivateModalDragger = (className) => {
    return new Promise((resolve, reject) => {
        try {
            interact(className).unset()
            resolve('Modal has stopped')
        } catch(err) {
            reject(err)
        }
    })
}

export default Module;