const initialState = {};

// Use the initialState as a default value
export default function appReducer(state = initialState, action) {

    // The reducer normally looks at the action type field to decide what happens
    console.log(`ACTION -> ${action.type}`);

    switch (action.type) {

        default: {
            return Object.assign({}, state, action.payload);
        }
    }

}