import { Component } from 'react';
import { despatchAction, ACTION_TYPES } from '../../redux/actions';
import { connect } from 'react-redux';
import QS from '../../lib/lem-parse-querystring';

class Session extends Component {

    componentDidMount = () => {
        console.log('Getting Session Parameters')
        const params = QS.parse();
        if (QS.validParams(params)) {
            console.log('Session Parameters are valid')
            this.props.despatchAction(ACTION_TYPES.getSessionParams, { params: params });
        }
    }
    render() { return ('') }
}

export default connect(null, { despatchAction })(Session)