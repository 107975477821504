let Module = {};

Module.newROIElement = (data) => {
    return new Promise( (resolve, reject) => {
        try {
            let element;

            element = document.createElement('div');
            element.className = 'rectangle';
            element.setAttribute('id', data.roiName)
            element.style.width = '100px';
            element.style.height = '25px';
            element.style.touchAction = 'none';

            if (Object.keys(data.coords).length > 0) {
                // let widthPer = (data.coords.width * 100) / data.imgData.canvasWidth
                // let heightPer = (data.coords.height * 100) / data.imgData.canvasHeight

                // console.log('PERSENTAGES: ', widthPer, heightPer)

                element.style.width = `${data.coords.width}px`;
                element.style.height = `${data.coords.height}px`;
                element.style.position = 'absolute'
                element.style.top = `${data.coords.top + 10}px`
                element.style.left = `${data.coords.left + 15}px`
            }

            if (data.BoxType === 1) {
                element.style.border = "1px solid red";
            } else if ( data.BoxType === 2) {
                element.style.border = "1px solid blue";
            } else if ( data.BoxType === 3) {
                element.style.border = "1px solid yellow";
            } else {
                element.style.border = "1px solid black";
            }

            resolve(element)

        } catch(err) {
            reject(err)
        }
    })
}

Module.newNodeElement = (event, nodeNum, imgElement, coords) => {
    return new Promise( (resolve, reject) => {
        try {
            let element;
            // let dim = '8px'
            let dim = 8

            element = document.createElement('div');
            element.className = 'clip_node';
            element.setAttribute('id', `node-${nodeNum}`)
            element.style.position = 'absolute';
            // element.style.top = ((event.clientY + window.pageYOffset) - imgElement.elementY) + 'px';
            // element.style.top = coords.top + 'px';
            // element.style.left = coords.left + 'px';
            element.style.top = (coords.coords.top - imgElement.elementY) + 'px';
            element.style.left = (coords.coords.left - imgElement.elementX) + 'px';
            element.style.height = `${dim}px`;
            element.style.width = `${dim}px`;
            element.style.borderRadius = '50%';
            element.style.backgroundColor = 'red';
            element.style.marginLeft = `-${dim/2}px`
            element.style.marginTop = `-${dim/2}px`
            element.style.touchAction = 'none';
            element.style.zIndex = '1'

            resolve(element)

        } catch(err) {
            reject(err)
        }
    })
}

export default Module;