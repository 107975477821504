import { ACTION_TYPES as TYPES } from './actionTypes';

export const ACTION_TYPES = TYPES;

export const despatchAction = (type, payload) => ({
    type: type,
    payload: payload
});

export const Logger = {

    log: (e) => {
        if (e instanceof Error) console.error(e);
        else console.log(e);
    }

};