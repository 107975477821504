import React, { Component } from 'react';
import {Button, Modal} from 'react-bootstrap';
// import merge from 'lodash.merge';

import { connect } from 'react-redux';
import { despatchAction, ACTION_TYPES } from '../../redux/actions';

import Convertor from '../../lib/lem-coords-convertor';
import API from '../../lib/lem-api'

import ExportBoxes from '../Forms/ExportBoxes';
import LoadingSpinner from '../Spinner';
// import TempImageDownloader from '../Forms/TempImageDownloader';

class ExportModal extends Component {

    state = {
        includeBackImage: false,
        sendingFile: false,
        sendingJson: false
    }

    clearLocalStorage = () => {
        this.props.despatchAction(ACTION_TYPES.displayExportModal, {"displayExportModal": false})
        setTimeout(() => {
            window.localStorage.clear();
            window.location.reload() 
        }, 1000);      
    }

    closeModal = () => {
        this.props.despatchAction(ACTION_TYPES.displayExportModal, {"displayExportModal": false})
        this.props.despatchAction(ACTION_TYPES.documentTypeHandler, {"documentType": null})
        this.props.despatchAction(ACTION_TYPES.ownCountryCodeHandler, {"enterOwnCountryCode": false})
        this.props.despatchAction(ACTION_TYPES.countryCodeHandler, {"countryCode": null})
        this.props.despatchAction(ACTION_TYPES.templateNameHandler, {"templateName": null})
    }

    includeBackImage = (event) => {
        console.log('INCLUDING BACK', event.target.checked)
        this.setState({
            includeBackImage: event.target.checked
        })
    }

    uploadImageToS3 = (selectedS3File, fileName, documentSide) => {
        const payload = {
            token: this.props.params.token
        }
        fetch(selectedS3File)
            .then(res => res.blob())
            .then(blob => new File([new Blob([blob])], fileName))
            .then(blobFile => {
                console.log("BLOB FILE: ", blobFile)
                
                API.getCroppedSignedUrl(payload, fileName, documentSide)
                .then(async signedUrl => {
                    console.log('SignedULR: ', signedUrl)
                    const url = signedUrl.url

                    try{
                        const response = await API.sendFile(url, blobFile, ((event) => { this.setState({ progress: ((event.loaded / event.total) * 100) }); }))
                        console.log('SEND FILE RESPONSE: ', response)

                        if ( !this.props.selectedS3File.verso ) {
                            // this.closeModal();
                            this.clearLocalStorage();
                            this.setState({
                                sendingFile: false
                            })
                        } else {
                            if ( documentSide === 'back' ) {
                                // this.closeModal();
                                this.clearLocalStorage();
                                this.setState({
                                    sendingFile: false
                                })
                            }
                        }
                    } catch (error) {
                        console.log('send file error: ', error)
                    }
                })
                .catch(error => {
                    console.log('No Signed URL: ', error)
                })
            })
    }

    uploadImageToS3Hander = () => {
        let rectoElements = this.props.sidedBoxElementsArray
        let selectedFile = this.props.selectedFile
        let selectedRecto
        let selectedVerso
        let fileNameRecto

        console.log('VERSO ELEMENTS: ', rectoElements)

        if (selectedFile) {
            alert('No need to send local file')
        } else {
            if ( rectoElements ) {
                console.log('SENDING FRONT AND BACK')
                // Send front and back
                selectedRecto = this.props.selectedS3File.recto.dataURL
                selectedVerso = this.props.selectedS3File.verso.dataURL
                fileNameRecto = this.props.newTempImageFileName;
                this.setState({
                    sendingFile: true
                }, () => {
                    this.uploadImageToS3(selectedRecto, fileNameRecto, 'front')
                    this.uploadImageToS3(selectedVerso, fileNameRecto, 'back')
                })
            } else {
                console.log('SENDING FRONT')
                selectedRecto = this.props.selectedS3File.recto.dataURL
                fileNameRecto = this.props.newTempImageFileName;
                this.setState({
                    sendingFile: true
                }, () => {
                    this.uploadImageToS3(selectedRecto, fileNameRecto, 'front')
                })
            }
        }
    }

    extractJsonHandler = () => {
        let country = this.props.countryCode;
        let fileName = this.props.templateName;
        let versoElements = this.props.boxElementsArray;
        let rectoElements = this.props.sidedBoxElementsArray
        // let jsondata = this.props.jsondata;
        let documentType = this.props.documentType;
        let imgElement = this.props.imgElement
        let rectoCollection = [];
        let roiCollection = [];
        let rectoPromises
        let versoPromises

        this.setState({
            sendingJson: true
        })

        if (!fileName) {
            alert('Please provide a template name before exporting JSON')
        } else {
            if (fileName.length === 0) {
                alert('Please provide a template name before exporting JSON')
            }
        }

        console.log('rectoElements: ', rectoElements)
        console.log('versoElements: ', versoElements)
        console.log('ImageElement: ',  this.props.imgElement)

        // /*
        
        if ( rectoElements ) {
            let recto = rectoElements.recto
            rectoPromises = Object.entries(recto).map( async roi => {
                try {
                    console.log('ROI :', roi)
                    let roiValue = roi[1]
                    const response = await Convertor.toTrue(roiValue, imgElement);
                    // console.log('RESPONSE: ', response)
                    return rectoCollection.push(response);
                } catch (error) {
                    console.log(error)
                    alert('An error occured with Recto Promise', error.message)
                }
            })
        }

        if ( versoElements ) {
            versoPromises = Object.entries(versoElements).map( async roi => {
                try {
                    console.log('ROI :', roi)
                    let roiValue = roi[1]
                    const response = await Convertor.toTrue(roiValue, imgElement);
                    // console.log('RESPONSE: ', response)
                    return roiCollection.push(response);
                } catch (error) {
                    console.log(error)
                    alert('An error occured with Verso Promise', error.message)
                }
            })
        }

        

        // Wait for all Promises to complete
        Promise.all([rectoPromises, versoPromises])
        .then(results => {
            console.log('roiCollection: ', roiCollection)
            let fileNameCollection
            // Handle results
            if ( this.props.sidedBoxElementsArray ) {
                fileNameCollection = {
                    [fileName]: rectoCollection,
                    [`${fileName}_verso`]: roiCollection
                }
            } else {
                fileNameCollection = {
                    [fileName]: roiCollection
                }
            }

            console.log('FILENAME: ', fileNameCollection)

            // let countryCollection = {
            //     [country]: fileNameCollection
            // }

            let template = {
                iso2: country,
                documentType,
                templateId: fileName,
                templateData: fileNameCollection
            }
            const payload = {
                token: this.props.params.token
            }

            console.log('RESPONSE: ', template, payload)

            API.putTemplateBack(payload, template)
            .then(response => {
                console.log('FINAL RESPONSE: ', response)

                this.setState({
                    sendingJson: false
                }, () => {
                    this.uploadImageToS3Hander()
                })
                // this.closeModal()
                // this.props.ClearLocalStorage();
            })
            .catch(error => {
                console.error(error)
            })
        })
        .catch(e => {
            console.error(e);
        })

        // */

    }

    offlineExtractJsonHandler = () => {
        let country = this.props.countryCode;
        let fileName = this.props.templateName;
        let boxElements = this.props.boxElementsArray;
        let roiCollection = [];

        let promises = Object.entries(boxElements).map( async roi => {
            try {
                console.log('ROI :', roi)
                let roiValue = roi[1]
                const response = await Convertor.toTrue(roiValue, this.props.imgElement);
                // console.log('RESPONSE: ', response)
                return roiCollection.push(response);
            } catch (error) {
                console.log(error)
                alert('An error occured, please reset and try again', error.message)
            }
        })

        Promise.all(promises)
        .then(results => {
            console.log('roiCollection: ', roiCollection)
            let fileNameCollection = {
                [fileName]: roiCollection
            }
            

            let countryCollection = {
                [country]: fileNameCollection
            }
            const json = JSON.stringify(countryCollection, null, '\t');
            const blob = new Blob([json], {type: 'application/json'});
            const href = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.download = fileName + '.json';
            document.body.appendChild(link);
            link.click()
            document.body.removeChild(link);

            this.props.ClearLocalStorage();
        })
        .catch(e => {
            console.error(e);
        })
    }

    render() {
        let params = this.props.params;
        let sendingFile = this.state.sendingFile
        let sendingJson = this.state.sendingJson
        let body

        if ( sendingFile ) {
            body =  <LoadingSpinner 
                        Message="Sending Image..."
                    />
        } else if ( sendingJson ) {
            body =  <LoadingSpinner 
                        Message="Sending Template..."
                    />
        } else {
            body = <div>
                <Modal.Body>
                    <ExportBoxes 
                        IncludeBackImage={this.includeBackImage}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeModal}>Close</Button>
                    { params ?
                        params.accountId && params.sessionId && params.token ?
                            <Button variant="success" onClick={this.extractJsonHandler}>Save to Master Template</Button>
                        :
                        <Button variant="success" onClick={this.offlineExtractJsonHandler}>Save to Master Template</Button>
                    :
                        <Button variant="success" onClick={this.offlineExtractJsonHandler}>Save to Master Template</Button>
                    }
                    
                </Modal.Footer>
            </div>
        }
        return(
            <Modal show={this.props.displayExportModal} onHide={this.closeModal}>
                <Modal.Header>
                    <Modal.Title>Finishing Touches</Modal.Title>
                </Modal.Header>
                { body }
            </Modal>
        )
    }
}

const mapStateToProps = state => { return {
    displayExportModal: state.displayExportModal,
    jsondata: state.jsondata, 
    documentType: state.documentType,
    countryCode: state.countryCode,
    templateName: state.templateName,
    boxElementsArray: state.boxElementsArray,
    documentTemplate: state.documentTemplate,
    enterOwnCountryCode: state.enterOwnCountryCode,
    sidedBoxElementsArray: state.sidedBoxElementsArray,
    imgElement: state.imgElement,
    newTempImageFileName: state.newTempImageFileName,
    selectedFile: state.selectedFile,
    selectedS3File: state.selectedS3File,
    params: state.params
} };
export default connect(mapStateToProps, { despatchAction })(ExportModal)