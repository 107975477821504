export const ACTION_TYPES = {
   selectFile: "selectFile",
   loadJson: "loadJson",
   imgMetaInfo: "imgMetaInfo",
   displayNewRoiModal: "displayNewRoiModal",
   enterOwnBoxName: "enterOwnBoxName",
   boxName: "boxName",
   boxType: "boxType",
   selectedFielddataHandler: "selectedFielddataHandler",
   enterOwnMetadata: "enterOwnMetadata",
   boxElements: "boxElements",
   boxElementsHandler: "boxElementsHandler",
   displayExportModal: "displayExportModal",
   documentTypeHandler: "documentTypeHandler",
   ownCountryCodeHandler: "ownCountryCodeHandler",
   countryCodeHandler: "countryCodeHandler",
   templateNameHandler: "templateNameHandler",
   // fromTemplateModalHandler: "fromTemplateModalHandler",
   // documentTemplateHandler: "documentTemplateHandler",
   // templateDataHandler: "templateDataHandler",
   startEditHandler: "startEditHandler",
   downloadTempImageModalHandler: "downloadTempImageModalHandler",
   tempImageFileName: "tempImageFileName",
   tempImageFileExtension: "tempImageFileExtension",
   startImageCropperHandler: "startImageCropperHandler",
   cropperNodeHandler: "cropperNodeHandler",
   getSessionParams: "getSessionParams",
   documentSide: "documentSide",
   activeSide: "activeSide",
   activeTemplate: "activeTemplate",
   cropStatus: "cropStatus"
};