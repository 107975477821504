import React, { Component } from 'react';
import {Form} from 'react-bootstrap';

import { connect } from 'react-redux';
import { despatchAction, ACTION_TYPES } from '../../redux/actions';

import LoadingSpinner from '../Spinner';

import LEM_API from '../../lib/lem-api';

class ExportBoxes extends Component {

    state = {
        countryCodeList: [],
        countryCodeCounter: 0,
        isLoadingAPI: false
    }

    docTypeHandler = (event) => {
        this.setState({countryCodeCounter: 0})
        if (this.props.params ) {
            this.countryHandler(event.target.value);
        }
        
        this.props.despatchAction(ACTION_TYPES.documentTypeHandler, {"documentType": event.target.value})
    }

    enterOwnCountryCodeHandler = (event) => {
        if (event.target.checked) {
            this.props.despatchAction(ACTION_TYPES.ownCountryCodeHandler, {"enterOwnCountryCode": true})

        } else if (!event.target.checked) {
            this.props.despatchAction(ACTION_TYPES.ownCountryCodeHandler, {"enterOwnCountryCode": false})
        }
    }

    countryCodeHandler = (event) => {
        this.props.despatchAction(ACTION_TYPES.countryCodeHandler, {"countryCode": event.target.value})
    }

    templateNameHandler = (event) => {
        this.props.despatchAction(ACTION_TYPES.tempImageFileName, {"newTempImageFileName": event.target.value})
        this.props.despatchAction(ACTION_TYPES.templateNameHandler, {"templateName": event.target.value})
    }

    countryHandler = (documentType) => {
        let jsondata = this.props.jsondata
        let countries = [];

        this.setState({ isLoadingAPI: true }, async () => {
            if (documentType) {
                
                Object.keys(jsondata[documentType]).map(async country => {
                    let final_response;
                    try {
                        this.setState({countryCodeCounter: this.state.countryCodeCounter += 1})
                        const response = await LEM_API.getCountry(country)

                        final_response = {
                            Country: response,
                            Code: country
                        }
        
                        countries.push(final_response);
        
                        this.setState({
                            countryCodeList: countries
                        })
    
                        if ( countries.length === this.state.countryCodeCounter && countries.length > 0 ) {
                            this.setState({ isLoadingAPI: false })
                        }
                        
                        return countries
                    } catch (error) {
                        console.log(error)
                        final_response = {
                            Country: country,
                            Code: country
                        }
                        console.log('[ERROR] Country: ', final_response)
                        countries.push(final_response);
        
                        this.setState({
                            countryCodeList: countries
                        })
                    }
                })
            }
        })
    }

    render() {
        let jsondata = this.props.jsondata
        let documentType = this.props.documentType;
        let params = this.props.params;

        return (
            <div>
                <Form>
                    <Form.Group controlId="doc-select-export-form">
                        <Form.Label>Please Select Document Type</Form.Label>
                            { params ? 
                                params.accountId && params.sessionId && params.token ?
                                    <div>
                                        <Form.Control as="select" custom onChange={this.docTypeHandler}>
                                            <option>Document Type</option>
                                            {Object.keys(jsondata).map((docType, docTypeIndex) =>
                                                <option key={docTypeIndex}>{docType}</option>
                                            )}
                                        </Form.Control>
                                        <Form.Check id="EnterOwnCountryCodeCheck" type="checkbox" label="Enter Own Country Code?" onChange={this.enterOwnCountryCodeHandler} />
                                    </div>
                                :
                                    <div>
                                        <Form.Control type="text" placeholder="passport, drivinglicense, id" onChange={this.docTypeHandler} />
                                    </div>
                            :
                                <div>
                                    <Form.Control type="text" placeholder="passport, drivinglicense, id" onChange={this.docTypeHandler} />
                                </div>
                            }
                    </Form.Group>

                    { params ? 
                        params.accountId && params.sessionId && params.token ?

                            this.state.isLoadingAPI ?
                                <LoadingSpinner 
                                    Message="Loading Country List..."
                                />
                            :
                            <Form.Group controlId="country-select-export-form">
                                {!this.props.enterOwnCountryCode ? 
                                    <div>
                                        <Form.Label>Please Select Document Country</Form.Label>
                                        <Form.Control as="select" custom onChange={this.countryCodeHandler}>
                                            <option>Country Code</option>
                                            { documentType ? 
                                                // (a,b) => a.Country > b.Country
                                                this.state.countryCodeList.sort( (a, b) => {
                                                    return b.Country < a.Country ? 1
                                                    : b.Country > a.Country ? -1
                                                    : 0;
                                                }).map((country, countryIndex) =>
                                                    <option key={countryIndex} value={country.Code}>{country.Country} - {country.Code}</option>
                                                ) 
                                            :
                                                ''
                                            }
                                        </Form.Control>
                                    </div>
                                : 
                                    <div>
                                        <Form.Label>Enter Country Code</Form.Label>
                                        <Form.Control type="text" placeholder="Country Code (iban code)" onChange={this.countryCodeHandler} />
                                    </div>
                                }
                            </Form.Group>
                        :
                            <div>
                                <Form.Label>Enter Country Code</Form.Label>
                                <Form.Control type="text" placeholder="Country Code (iban code)" onChange={this.countryCodeHandler} />
                            </div>
                    :
                        <div>
                            <Form.Label>Enter Country Code</Form.Label>
                            <Form.Control type="text" placeholder="Country Code (iban code)" onChange={this.countryCodeHandler} />
                        </div>
                    }
                    <Form.Group controlId="doc-name-export-form">
                        <div>
                            <Form.Label>Enter TemplateId</Form.Label>
                            <Form.Control type="text" placeholder="Replace spaces with underscores" onChange={this.templateNameHandler} />
                        </div>
                    </Form.Group>
                    {/* <Form.Group controlId="file-extension-check">
                        <div>
                            <Form.Check id="back" type="checkbox" label="Include Back Image" onChange={(event) => this.props.IncludeBackImage(event)} />
                        </div>
                    </Form.Group> */}
                </Form>
            </div>
        )
    }
}

const mapStateToProps = state => { return {
    jsondata: state.jsondata, 
    documentType: state.documentType,
    enterOwnCountryCode: state.enterOwnCountryCode,
    selectedFile: state.selectedFile,
    countryCode: state.countryCode,
    selectedS3File: state.selectedS3File,
    params: state.params
} };
export default connect(mapStateToProps, { despatchAction })(ExportBoxes)