import React, { Component } from 'react';
import {DropdownButton, Dropdown} from 'react-bootstrap';

import { connect } from 'react-redux';
import { despatchAction } from '../../redux/actions';

class DeleteButton extends Component {
    render() {
        let boxElements = this.props.boxElementsArray;
        let deleteButton;
        if (boxElements) {
            if (Object.keys(boxElements).length === 0) {
                deleteButton = ''
            } else {
                deleteButton =  <DropdownButton
                                    // as={ButtonGroup}
                                    key='up'
                                    id="remove-btn"
                                    drop='right'
                                    variant="danger"
                                    title="Delete ROI"
                                    
                                >
                                {   Object.keys(boxElements).map( (element, index) => (
                                        <Dropdown.Item key={index} eventKey={index} onClick={() => this.props.deleteRoiCallback(element, index)}>{element}</Dropdown.Item>
                                    ))
                                }
                                </DropdownButton>
            }
        }

        return (
            <div>
                {deleteButton}
            </div>
        )
    }
}

const mapStateToProps = state => { return {
    boxElementsArray: state.boxElementsArray
} };
export default connect(mapStateToProps, { despatchAction })(DeleteButton)