import React from 'react';
import './Imageviewer.css';


// class CropArea extends Component {
    
//     render() {

        // let topLeft = this.props.topLeft
        // let topRight = this.props.topLeft
        // let bottomRight = this.props.topLeft
        // let bottomLeft = this.props.topLeft

        // console.log('topLeft: ', topLeft)
        // console.log('topRight: ', topRight)
        // console.log('bottomRight: ', bottomRight)
        // console.log('bottomLeft: ', bottomLeft)

//         return(
//             // <div id='crop-area' style={{ shapeOutside: polygon() }}>
//             <div id='crop-area'>
//                 <p>hello world</p>
//             </div>
//         )
//     }
// }

function CropArea(props) {

    let topLeft = props.topLeft
    let topRight = props.topRight
    let bottomRight = props.bottomRight
    let bottomLeft = props.bottomLeft

    console.log('topLeft: ', topLeft)
    console.log('topRight: ', topRight)
    console.log('bottomRight: ', bottomRight)
    console.log('bottomLeft: ', bottomLeft)

    return(
        // <div id='crop-area' style={{ shapeOutside: polygon() }}>
        <div id='crop-area'>
            
        </div>
    )
}

export default CropArea