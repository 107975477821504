import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './Main.css';

import axios from "axios"

import { connect } from 'react-redux';
import { despatchAction, ACTION_TYPES } from '../../redux/actions';

import Maincontainer from '../Maincontainer';
import Infocontainer from '../Infocontainer';
import ExportModal from '../Modal/ExportModal';
import TempImageModal from '../Modal/TempImageModal';

import LoadingSpinner from '../Spinner';

import HtmlElement from '../../lib/lem-element'
import Upload from '../../lib/lem-upload';
import CONFIG from '../../lib/config';

import jsonPackage from '../../../package.json';
import Doccontainer from '../Doccontainer';
// const axios = require('axios');


// import FromTemplate from '../Modal/FromTemplate';

class AfterStart extends Component {

    state = {
        isLoadingAPI: false
    }

    showExportModal = () => {
        this.props.despatchAction(ACTION_TYPES.displayExportModal, {"displayExportModal": true})
        this.props.despatchAction(ACTION_TYPES.countryCodeHandler, {"countryCode": false})
    }

    showAddNewModal = () => {
        this.props.despatchAction(ACTION_TYPES.displayNewRoiModal, {"displayAddNewRoiModal": true})
    }

    deleteRoiHandler = (element, index) => {
        let key = element
        let boxElements = this.props.boxElementsArray
        let htmlElement = document.getElementById(element)
        let fielddata = this.props.fielddata;
        var selectedFielddata = this.props.selectedFielddata;

        fielddata.push( selectedFielddata[element] )

        this.props.despatchAction(ACTION_TYPES.loadJson, {fielddata})

        delete boxElements[key]
        htmlElement.remove()

        this.props.despatchAction(ACTION_TYPES.boxElementsHandler, {"boxElementsArray": Object.assign({}, boxElements)})
    }

    updateFielddataHandler = (fielddata, boxName) => {
        var selectedFielddata = this.props.selectedFielddata; // {}
        console.log('BOXNAME: ', boxName)
        fielddata.map((data, index) => {
            if (data.name === boxName) {
                console.log('[INFO] : ', data.name, boxName)
                selectedFielddata[data.name] = fielddata[index]
                this.props.despatchAction(ACTION_TYPES.selectedFielddataHandler, {"selectedFielddata" : Object.assign({}, selectedFielddata)})

                fielddata.splice(index, 1)
                this.props.despatchAction(ACTION_TYPES.loadJson, {fielddata})
            } else {
                console.log(`${boxName} is not in fields.json - please add`)
            }
            return 'completed'
        })
    }

    boxMovedHander = (element, rect) => {
        this.props.boxElementsArray[element.id].coords = rect;
        this.props.boxElementsArray[element.id].interact = true;
        this.props.despatchAction(ACTION_TYPES.boxElementsHandler, {"boxElementsArray": Object.assign({}, this.props.boxElementsArray)})
    }

    createRoiHtmlElement = (roi) => {
        let canvas = document.getElementById('Imageviewer');
        HtmlElement.newROIElement(roi)
        .then(response => {
            canvas.appendChild(response)
        })
        .catch(err => {
            console.log(err)
        })
    }

    downloadTemplateImageHandler = () => {
        this.props.despatchAction(ACTION_TYPES.downloadTempImageModalHandler, {"displayTempImageDownloaderModal": true})
    }

    stopImageCropper = () => {
        this.props.despatchAction(ACTION_TYPES.startImageCropperHandler, {"displayCropperOverlay": false})
        this.props.despatchAction(ACTION_TYPES.cropperNodeHandler, {"cropperNodes": {}})
    }

    startImageCropper = (condition) => {
        this.props.despatchAction(ACTION_TYPES.startImageCropperHandler, {"displayCropperOverlay": condition})
        if (condition === true) {
            this.props.despatchAction(ACTION_TYPES.cropperNodeHandler, {"cropperNodes": {}})
        } else if (condition === false) {
            let nodes = this.props.cropperNodes
            let image = this.props.selectedFile;
            let imgMeta = this.props.imgElement
            let selectedS3File = this.props.selectedS3File;
            let params = this.props.params;
            let sessionId;
            let xRatio;
            let yRatio;
            let dataUrl;
            let coords = []
            // "[(73, 239), (356, 117), (475, 265), (187, 443)]"

            if (image || selectedS3File) {
                if ( selectedS3File ) {
                    image = selectedS3File[this.props.activeSide]
                }

                console.log('IMAGE: ', image)
                xRatio = image.imgWidth / imgMeta.elementWidth
                yRatio = image.imgHeight / imgMeta.elementHeight
                dataUrl = image.dataURL
            }

            Object.entries(nodes).map(node => {
                console.log('NODES: ', node)
                let x = ((node[1].coords.left - imgMeta.elementX) * xRatio)
                let y = ((node[1].coords.top - node[1].elementY) * yRatio)
                let string = `(${x}, ${y})`;
                return coords.push(string)
            })
            let stringCoords = `[${coords.toString()}]`

            let dataURLshortString = dataUrl.slice(0, 50)
            console.log('DataURL base64: ', dataURLshortString)

            let base64 = dataUrl.replace(/data:image\/(png|jpg|jpeg);base64,/g, "");

            let shortString = base64.slice(0, 50)
            console.log('Short base64: ', shortString)
            console.log(base64.length / 1024 / 1024)

            console.log(`[INFO] - Version${jsonPackage.version}`)

            if (params) {
                sessionId = params.sessionId
            }

            const payload = {
                "id": 1,
                "sessionId": sessionId,
                "coordinates": stringCoords,
                "dataURL": base64
            }

            this.setState({ isLoadingAPI: true }, () => {
                let url = `${ CONFIG.cropperURL }/image/processing/crop`
                axios.post(url, payload)
                .then(response => {
                    console.log(`RESPONSE from v${jsonPackage.version}`, response)
                    
                    let options = {
                        maxSizeMB: 3,
                        maxWidthOrHeight: 2884
                    }

                    let responsedata = response.data.image
                    let fixedImage = responsedata.slice(2, responsedata.length - 1)
                    // let croppedImage = `data:image/jpeg;base64,${fixedImage}`

                    let shortString = fixedImage.slice(0, 50)
                    console.log('FIXED IMAGE base64: ', shortString)

                    const byteCharacters = window.atob(fixedImage)
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], {type: 'image/jpeg'});

                    Upload.compressImage(blob, options)
                    .then(compressedImage => {
                        console.log('Compressed Size', compressedImage.size / 1024 / 1000, 'MB')

                        const reader = new FileReader();
                        const callback = async (data) => {
                            Upload.analyseImage(data)
                            .then(response => {
                                this.setState({ isLoadingAPI: false })

                                this.props.despatchAction(ACTION_TYPES.cropStatus, {"cropComplete": true})
                                
                                if (image) {
                                    if (selectedS3File) {
                                        selectedS3File[this.props.activeSide] = response
                                        this.props.despatchAction(ACTION_TYPES.selectFile, {"selectedS3File": Object.assign({}, selectedS3File)})
                                    } else {
                                        this.props.despatchAction(ACTION_TYPES.selectFile, {"selectedFile": response})
                                    }
                                }
                            })
                        }
                        reader.onload = function() {
                            callback(reader.result)
                        }
                        reader.readAsDataURL(compressedImage);
                    })
                })
                .catch(error => {
                    console.log(error);
                });
            })
        }
    }

    changeDocumentSide = () => {
        let sidedBoxElementsArray = {}
        let ActiveSide = this.props.ActiveSide;
        let boxElementsArray = this.props.boxElementsArray
        let selectedFielddata = this.props.selectedFielddata;
        let fielddata = this.props.fielddata;

        sidedBoxElementsArray[ActiveSide] = boxElementsArray

        Object.entries(selectedFielddata).map(selectedFielddataItem => {
            return fielddata.push( selectedFielddata[selectedFielddataItem[0]] )
        })

        this.props.despatchAction(ACTION_TYPES.loadJson, {fielddata})
        this.props.despatchAction(ACTION_TYPES.boxElementsHandler, {"sidedBoxElementsArray": Object.assign({}, sidedBoxElementsArray)})
        this.props.despatchAction(ACTION_TYPES.activeSide, {"activeSide": 'verso' })
        this.props.despatchAction(ACTION_TYPES.boxElementsHandler, {"boxElementsArray": Object.assign({}, {})})
        document.querySelectorAll('.rectangle').forEach(e => e.remove());
    }

    render() {
        let boxElementsArray = this.props.boxElementsArray;
        let displayCropperOverlay = this.props.displayCropperOverlay;
        let params = this.props.params;

        let ActiveSide = this.props.ActiveSide;
        let selectedS3File = this.props.selectedS3File;
        let documentSide = this.props.documentSide
        let activeS3File;
        let validForVerso;
        let showS3Image;

        if ( documentSide ) {
            if ( documentSide.verso && ActiveSide === 'recto' ) {
                validForVerso = true
            }
        } else {
            validForVerso = false
        }

        if ( ActiveSide && selectedS3File ) {
            activeS3File = selectedS3File[ActiveSide]
        }

        if ( this.props.selectedFile || (activeS3File ? activeS3File : '') ) {
            showS3Image = true
        } else {
            showS3Image = false
        }
        
        return(
            <Container fluid >
                <Row>
                    <Col xs={3} className="infoContainer" style={{height: '90vh', overflow: "scroll"}}>
                        { params ?
                            params.accountId && params.sessionId && params.token ? 
                                <div>
                                    <h4 style={{textAlign: 'center'}}>Existing Templates</h4>
                                    <Doccontainer
                                        updateFielddataCallback = { this.updateFielddataHandler }
                                        boxMovedCallback = { this.boxMovedHander }
                                        isCreatingRoiCallbackHandler = { this.isCreatingRoiCallbackHandler }
                                    />
                                    <p style={{textAlign: 'center', marginTop: '20px'}}>You can import an existing template by selecting it in the dropdown above</p>
                                </div>
                                
                            :
                            <p>Failed to load Template Panel</p>
                        :
                            <p>Failed to load Template Panel</p>
                        }
                        
                    </Col>
                    <Col xs={6}>
                        {!this.state.isLoadingAPI ?
                            showS3Image ?
                                <Maincontainer 
                                    showAddNewModalCallback = { this.showAddNewModal }
                                    deleteRoiCallback = { this.deleteRoiHandler }
                                    updateFielddataCallback = { this.updateFielddataHandler }
                                    boxMovedCallback = { this.boxMovedHander }
                                    selectedFile = { this.props.selectedFile || selectedS3File[ActiveSide] }
                                />
                            :
                                ''
                        : 
                        <LoadingSpinner 
                            Message="Loading Cropped Image"
                            Height="80%"
                        />
                        }

                    </Col>
                    <Col xs={3}>
                        <Row>
                            <Col>
                                <div className='btn-container'>
                                    <Button className='btn btn-afterstart' onClick={this.props.ClearLocalStorage}>Reset</Button>
                                </div>

                                {/* <div className='btn-container'>
                                    <Button className='btn btn-afterstart' onClick={this.downloadTemplateImageHandler} variant="secondary" >Save Template Image</Button>
                                </div> */}

                                { displayCropperOverlay ?
                                    <div>
                                        { Object.keys(this.props.cropperNodes).length > 3 ?
                                            <div className='btn-container'>
                                                <Button className='btn btn-afterstart' onClick={() => this.startImageCropper(false)} variant="success" >Submit Crop</Button>
                                            </div>
                                            :
                                            <div className='btn-container'>
                                                <Button className='btn btn-afterstart' variant="success" disabled >Submit Crop</Button>
                                            </div>
                                        }
                                        <div className='btn-container'>
                                            <Button className='btn btn-afterstart' onClick={this.stopImageCropper} variant="warning" >Cancel Crop</Button>
                                        </div>
                                    </div>
                                    :
                                    <div className='btn-container'>
                                        <Button className='btn btn-afterstart' onClick={() => this.startImageCropper(true)} variant="secondary" >Start Image Crop</Button>
                                    </div>
                                }

                                { boxElementsArray && Object.keys(boxElementsArray).length > 0 ?
                                    validForVerso ?
                                        <div className='btn-container'>
                                            <Button className='btn btn-afterstart' onClick={ this.changeDocumentSide } variant='success'>Template Verso</Button>
                                        </div>
                                        :
                                        <div className='btn-container'>
                                            <Button className='btn btn-afterstart' onClick={this.showExportModal} variant='success'>Save Template</Button>
                                        </div>
                                    :
                                        ''
                                }

                                <hr></hr>

                                <div className="infoContainer" style={{height: '50vh', overflow: "scroll"}}>
                                    { boxElementsArray ?
                                        Object.values(boxElementsArray).map( (entry, index ) => (
                                            <Infocontainer 
                                                key={index}
                                                Roi={entry}
                                                Index={index}
                                                createHTMLELementCallback = { this.createRoiHtmlElement }
                                            />
                                        ))
                                    :
                                        ''
                                    }
                                </div>
                            </Col>
                        </Row>
                        { boxElementsArray && Object.keys(boxElementsArray).length > 0 ? 
                            <div>
                                <hr></hr>
                                <Row style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                                    <img src="/arrow_down.png" alt="arrow down" className="arrow" />
                                </Row>
                            </div>
                        :
                            ''
                        }
                    </Col>
                </Row>
                <Row>
                    <ExportModal 
                        ClearLocalStorage = {this.props.ClearLocalStorage}
                    />
                    <TempImageModal />
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => { return { 
    boxElementsArray: state.boxElementsArray, 
    selectedFile: state.selectedFile, 
    imgElement: state.imgElement,
    boxName: state.boxName, 
    selectedFielddata: state.selectedFielddata,
    fielddata: state.fielddata,
    displayCropperOverlay: state.displayCropperOverlay,
    cropperNodes: state.cropperNodes,
    selectedS3File: state.selectedS3File,
    documentSide: state.documentSide,
    activeSide: state.activeSide,
    params: state.params,
    cropComplete: state.cropComplete
} };
export default connect(mapStateToProps, { despatchAction })(AfterStart)