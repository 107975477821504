import React, { Component } from 'react';
import {Button, Modal} from 'react-bootstrap';

import { connect } from 'react-redux';
import { despatchAction, ACTION_TYPES } from '../../redux/actions';

import API from '../../lib/lem-api'

import TempImageDownloader from '../Forms/TempImageDownloader';
import LoadingSpinner from '../Spinner';

class TempImageModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            documentSide: null,
            sendingFile: false,
        }
    }

    closeModal = () => {
        this.props.despatchAction(ACTION_TYPES.downloadTempImageModalHandler, {"displayTempImageDownloaderModal": false})
    }

    fileExtensionChecker = (side) => {
        this.setState({
            documentSide: side
        })
    }

    uploadImageToS3 = (selectedS3File, fileName, documentSide) => {
        const payload = {
            token: this.props.params.token
        }
        fetch(selectedS3File)
            .then(res => res.blob())
            .then(blob => new File([new Blob([blob])], fileName))
            .then(blobFile => {
                console.log("BLOB FILE: ", blobFile)

                this.setState({
                    sendingFile: true
                })
                
                API.getCroppedSignedUrl(payload, fileName, documentSide)
                .then(async signedUrl => {
                    console.log('SignedULR: ', signedUrl)
                    const url = signedUrl.url

                    try{
                        const response = await API.sendFile(url, blobFile, ((event) => { this.setState({ progress: ((event.loaded / event.total) * 100) }); }))
                        console.log('SEND FILE RESPONSE: ', response)
                        this.closeModal();
                        this.setState({
                            sendingFile: false
                        })
                    } catch (error) {
                        console.log('send file error: ', error)
                    }
                })
                .catch(error => {
                    console.log('No Signed URL: ', error)
                })
            })
    }

    downloadTemplateImage = () => {
        let documentSide = this.state.documentSide
        let selectedFile = this.props.selectedFile
        let selectedS3File
        let fileName
        
        if ( documentSide === 'front' ) {
            if ( !this.props.selectedS3File.recto) {
                alert('No FRONT image available, try sending BACK')
            } else {
                selectedS3File = this.props.selectedS3File.recto.dataURL
                fileName = `${this.props.newTempImageFileName}.jpeg`;
                this.uploadImageToS3(selectedS3File, fileName, documentSide)
            }
            
        } else if ( documentSide === 'back' ) {
            if ( !this.props.selectedS3File.verso ) {
                alert('No BACK image available, try sending FRONT')
            } else {
                selectedS3File = this.props.selectedS3File.verso.dataURL
                fileName = `${this.props.newTempImageFileName}_verso.jpeg`;
                this.uploadImageToS3(selectedS3File, fileName, documentSide)
            }
        } else {
            if (selectedFile) {
                alert('No need to send local file')
            } else {
                alert('No side selected')
            }
        }
    }

    render() {
        let sendingFile = this.state.sendingFile
        let body

        if ( sendingFile ) {
            body =  <LoadingSpinner 
                        Message="Sending Image..."
                    />
        } else {
            body =  <TempImageDownloader 
                        FileExtensionChecker = {this.fileExtensionChecker}
                    />
        }


        return(
            <Modal show={this.props.displayTempImageDownloaderModal} onHide={this.closeModal}>
                <Modal.Header>
                    <Modal.Title>Download Template Image</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    { body }
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeModal}>Close</Button>
                    <Button variant="success" onClick={this.downloadTemplateImage}>Send</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = state => { return {
    displayTempImageDownloaderModal: state.displayTempImageDownloaderModal,
    newTempImageFileName: state.newTempImageFileName,
    selectedFile: state.selectedFile,
    selectedS3File: state.selectedS3File,
    params: state.params
} };
export default connect(mapStateToProps, { despatchAction })(TempImageModal)