import React, { Component } from 'react';
import jsonPackage from '../../../package.json';
import './index.css'

class Version extends Component {
    render() {
        return (
            <div className="version">
                <p>v{ jsonPackage.version }</p>
            </div>
        );
    }
    
}

export default Version;