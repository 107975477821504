import React, { Component } from 'react';

import { connect } from 'react-redux';
import { despatchAction, ACTION_TYPES } from '../../redux/actions';

import { Modal } from 'react-bootstrap';

import NewRoiForm from '../Forms/NewRoiForm';
import Interact from '../../lib/lem-interact';

class AddNewRoiModal extends Component {
    closeModal = () => {
        this.props.despatchAction(ACTION_TYPES.displayNewRoiModal, {"displayAddNewRoiModal": false})
        this.props.despatchAction(ACTION_TYPES.enterOwnMetadata, {"enterOwnMetadata": false})
        this.props.despatchAction(ACTION_TYPES.enterOwnBoxName, {"enterOwnBoxName": false})
        this.props.despatchAction(ACTION_TYPES.boxName, {"boxName": ''})
    }

    activateInteract = async () => {
        await Interact.activateModalDragger('.modal-content');
    }

    deactivateInteract = async () => {
        await Interact.deactivateModalDragger('.modal-content');
    }
 
    render() {
        return(
            <Modal show={this.props.displayAddNewRoiModal} onHide={this.closeModal}>
                <Modal.Header className="add-new-roi-modal-header" onMouseEnter={this.activateInteract} onMouseLeave={this.deactivateInteract}>
                    <Modal.Title>ROI Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <NewRoiForm 
                        boxMovedCallback = {this.props.boxMovedCallback}
                        closeModal = {this.closeModal}
                        updateFielddataCallback = {this.props.updateFielddataCallback}
                        Fielddata = {this.props.Fielddata}
                        Image = { this.props.Image }
                    />
                </Modal.Body>
            </Modal>
        )
                
    }
}

const mapStateToProps = state => { return {
    displayAddNewRoiModal: state.displayAddNewRoiModal,
    enterOwnMetadata: state.enterOwnMetadata,
    enterOwnBoxName: state.enterOwnBoxName
} };
export default connect(mapStateToProps, { despatchAction })(AddNewRoiModal)