import React, { Component } from 'react';
import {Badge, Container, Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap';

class Infocontainer extends Component {

    onMouseOver = (index) => {
        try {
            let selectedElement = document.getElementsByClassName('rectangle')[index]
            selectedElement.style.backgroundColor = "rgba(190, 190, 190, 0.9)";
        } catch(err) {
            console.log(err)
        }
        
    }

    onMouseOut = (index) => {
        try {
            let selectedElement = document.getElementsByClassName('rectangle')[index]
            selectedElement.style.backgroundColor = "rgba(255, 255, 255, 0)";
        } catch(err) {
            console.log(err)
        }
        
    }

    componentDidMount() {
        this.props.createHTMLELementCallback(this.props.Roi)
    }

    render() {
        return (
            <Container onMouseOver={() => this.onMouseOver(this.props.Index)} onMouseOut={() => this.onMouseOut(this.props.Index)} fluid style={{marginBottom: '5px', paddingBottom: '5px'}}>
                <Row>
                    <Col xs={8}>
                        <Badge variant="success">{this.props.Roi.roiName}</Badge>
                    </Col>
                </Row>
                {/* <hr></hr> */}
                <Row>
                    <Col>
                        {this.props.Roi.metadataInput ?
                            this.props.Roi.metadataInput.map( (input, metaIndex) => (
                                <OverlayTrigger
                                    key={metaIndex}
                                    placement={'top'}
                                    overlay={
                                    <Tooltip id={`tooltip-${metaIndex}`}>
                                        { input.metadata[input.name] }
                                    </Tooltip>
                                    }
                                >
                                    <Badge variant="info" style={{marginRight: '5px'}}>{input.name}</Badge>
                                </OverlayTrigger>
                            ))
                        :
                        ''
                        }
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default Infocontainer;