import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import './index.css'

class LoadingSpinner extends Component {
    render() {
        return (
            <div className="spinner-container" style={{height: this.props.Height}}>
                <div className="spinner-sub">
                    <Spinner animation="border" />
                    <p>{this.props.Message}</p>
                </div>
            </div>
        );
    }
    
}

export default LoadingSpinner;