import React, { Component } from 'react';

import { connect } from 'react-redux';
import { despatchAction } from '../../redux/actions';

import LandingPage from '../LandingPage';

class Authenticate extends Component {

    clearLocalStorage = () => {
        window.localStorage.clear();
        setTimeout(() => {
            window.location.reload() 
        }, 1000);      
    }

    render() {
        return(
            <LandingPage 
                ClearLocalStorage = {this.clearLocalStorage}
            />
        )
    }
}

const mapStateToProps = state => { return {} };
export default connect(mapStateToProps, { despatchAction })(Authenticate)