import imageCompression from 'browser-image-compression';
import Resizer from 'react-image-file-resizer';

let Module = {};

Module.analyseImage = (dataURL) => {
    return new Promise((resolve, reject) => {
        Module.Image = Module.Image || new Image();

        Module.Image.onload = function() {
            try {
                let imgData = dataURL;
                let imgWidth = Module.Image.width;
                let imgHeight = Module.Image.height;

                console.log('Upload Width: ', imgWidth)
                console.log('Upload Height: ', imgHeight)

                resolve({
                    stamp: new Date(),
                    imgWidth,
                    imgHeight,
                    size: imgData.length,
                    dataURL: imgData
                })

            } catch (err) {reject(err)}
        }
        if (dataURL)
            Module.Image.src = dataURL;
        else
            resolve({});
    })
}

Module.compressImage = (blob, options) => {
    return new Promise((resolve, reject) => {
        imageCompression(blob, options)
        .then(compressedImage => {

            Resizer.imageFileResizer(
                compressedImage,
                2884,
                2024,
                'JPEG',
                90,
                0,
                uri => {
                    console.log('URI, ', uri)
                    resolve(uri)
                },
                'blob',
                2884,
                // 2024
            )

            // resolve(compressedImage)
        })
        .catch(err => {
            reject(err)
        })
    })
}

Module.convertImgToBase64URL = (url, outputFormat) => {
    return new Promise((resolve, reject) => {
        try {
            var img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = function(){
                var canvas = document.createElement('CANVAS'),
                ctx = canvas.getContext('2d'), dataURL;
                canvas.height = img.height;
                canvas.width = img.width;
                ctx.drawImage(img, 0, 0);
                dataURL = canvas.toDataURL(outputFormat);
                resolve(dataURL);
                canvas = null; 
            };
            img.src = url;
        } catch(error) {
            reject(error)
        }
    })
}

export default Module;