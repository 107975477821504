const qs = require('query-string');

let Module = {};

Module.parse = () => {
    const params = qs.parse(window.location.search || '');
    if (Module.validParams(params)) {
        params.url = window.location.href;
    }
    return params;
}

Module.validParams = (params) => {
    return params && params.sessionId && params.token;
}

export default Module;