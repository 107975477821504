import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import bsCustomFileInput from 'bs-custom-file-input';

import './Main.css';

import Upload from '../../lib/lem-upload';
import LEM_API from '../../lib/lem-api'
import QS from '../../lib/lem-parse-querystring';

import { connect } from 'react-redux';
import { despatchAction, ACTION_TYPES } from '../../redux/actions';

// import GetStarted from './GetStarted';
import AfterStart from './AfterStart';
import LoadingSpinner from '../Spinner';

class LandingPage extends Component {

    offlineTemplateCallback = () => {
        let jsonFiles = [
            {
                name: 'fielddata',
                url: '/json/fields.json'
            },
            {
                name: 'metadata',
                url: '/json/metadata.json'
            },
            {
                name: 'fieldtypes',
                url: '/json/fieldtypes.json'
            },
        ]

        jsonFiles.map(jsonFile => {
            const jsonCallback = async (jsonFile) => {
                LEM_API.fetchJson(jsonFile.url)
                    .then(response => {
                        this.props.despatchAction(ACTION_TYPES.loadJson, {[jsonFile.name]: response})
                    })
                    .catch(err => {
                        alert(`${jsonFile.name} is empty. Please check that '${jsonFile.url}' is correct`)
                    })
            }

            return jsonCallback(jsonFile)
        })
    }

    templateCallback = async( params ) => {
        try {
            const payload = {
                token: params.token
            }

            const templates = await LEM_API.getTemplates(payload)
            console.log('TEMPLATES: ', templates)

            Object.entries(templates).map( template => {
                console.log(template)
                if (template[0] === 'fields') {
                    this.props.despatchAction(ACTION_TYPES.loadJson, {"fielddata": template[1]})
                } else if ( template[0] === 'metaData' ) {
                    this.props.despatchAction(ACTION_TYPES.loadJson, {"metadata": template[1]})
                } else if ( template[0] === 'templateData' ) {
                    this.props.despatchAction(ACTION_TYPES.loadJson, {"jsondata": template[1]})
                } else if ( template[0] === 'fieldTypes') {
                    this.props.despatchAction(ACTION_TYPES.loadJson, {"fieldtypes": template[1]})
                }
                else {
                    this.props.despatchAction(ACTION_TYPES.loadJson, {[template[0]]: template[1]})
                }
                return 'completed'
            })


            // const headers = {
            //     headers: {
            //         'Authorization': token,
            //         "Content-Type": 'application/json'
            //     }
            // }

            // console.log('Headers: ', headers)

            // const templates = await axios.get(`${ CONFIG.baseURL }/templates`, headers)
            // // const templates = await axios.get('https://api.uat.lemverify.io/internal/v1/templates', headers)
            // Object.entries(templates.data).map( template => {
            //     console.log(template)
            //     if (template[0] === 'fields') {
            //         this.props.despatchAction(ACTION_TYPES.loadJson, {"fielddata": template[1]})
            //     } else if ( template[0] === 'metaData' ) {
            //         this.props.despatchAction(ACTION_TYPES.loadJson, {"metadata": template[1]})
            //     } 
            //     else if ( template[0] === 'templateData' ) {
            //         this.props.despatchAction(ACTION_TYPES.loadJson, {"jsondata": template[1]})
            //     } else if ( template[0] === 'fieldTypes') {
            //         this.props.despatchAction(ACTION_TYPES.loadJson, {"fieldtypes": template[1]})
            //     }
            //     else {
            //         this.props.despatchAction(ACTION_TYPES.loadJson, {[template[0]]: template[1]})
            //     }
            // }) 
        } catch (error) {
            console.log(error)
            alert('An error occured trying to get templates, please reset and try again')
        }
    }

    getImageUrlFromS3 = ( params ) => {
        try {
            const payload = {
                token: params.token,
                sessionId: params.sessionId
            }
    
            this.props.despatchAction(ACTION_TYPES.documentSide, {"documentSide": Object.assign({}, {}) })
            this.props.despatchAction(ACTION_TYPES.selectFile, {"selectedS3File": Object.assign({}, {}) })
    
            this.setState({ isLoadingSignedURL: true }, async () => {
                try {
                    const options = {
                        maxSizeMB: 3,
                        maxWidthOrHeight: 2884
                    }
                    const signedurl = await LEM_API.getSignedUrl(payload)
                    console.log('SignedURL: ', signedurl)
    
                    if (signedurl) {    
                        this.saveImageFromSignedURL(signedurl.recto, options, 'recto')
                        this.saveImageFromSignedURL(signedurl.verso, options, 'verso')
                    }

                    else {
                        alert('No SignedURL available...')
                    }
                } catch (error) {
                    console.log(error)
                    alert('An error occured trying to get SignedURL, please reset and try again', error.message)
                    this.props.despatchAction(ACTION_TYPES.getSessionParams, { params: {} });
                }
            })
        } catch (error) {
            alert('No SessionId detected...Use service via Internal Admin')
        }
    }

    saveImageFromSignedURL = (signedURL, options, documentSide) => {
        convertImgToBase64URL(signedURL, base64 => {
            // let Window = window || globalThis
            console.log('Short BASE 64: ', base64.slice(0, 55))
            // const basedata = base64.slice(22, base64.length - 1)
            const basedata = base64.replace(/.*base64,/, '')
            console.log('Short BASE data: ', basedata.slice(0, 55))
            const byteCharacters = window.atob(basedata)
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {type: 'image/jpeg'});
    
            console.log('BLOB: ', blob)
            Upload.compressImage(blob, options)
            .then(compressedImage => {
                console.log('Size', compressedImage.size / 1024 / 1000, 'MB')

                const reader = new FileReader();
                const callback = async (data) => {
                    Upload.analyseImage(data)
                    .then(response => {
                        let selectedS3File = this.props.selectedS3File
                        let documentSideState = this.props.documentSide

                        documentSideState[documentSide] = true
                        selectedS3File[documentSide] = response
                        this.setState({ isLoadingSignedURL: false }, () => {
                            console.log("DOCUMENTS: ", selectedS3File)
                            this.props.despatchAction(ACTION_TYPES.selectFile, {"selectedS3File": Object.assign({}, selectedS3File)})
                            this.props.despatchAction(ACTION_TYPES.documentSide, {"documentSide": Object.assign({}, documentSideState)})
                            this.props.despatchAction(ACTION_TYPES.activeSide, {"activeSide": 'recto' })
                        })
                    })
                }

                reader.onload = function() {
                    callback(reader.result)
                }
                reader.readAsDataURL(compressedImage);
            })
            .catch(error => {
                console.log(error)
            })
        })

        function convertImgToBase64URL(url, callback, outputFormat){
            var img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = function(){
                var canvas = document.createElement('CANVAS'),
                ctx = canvas.getContext('2d'), dataURL;
                canvas.height = img.height;
                canvas.width = img.width;
                ctx.drawImage(img, 0, 0);
                dataURL = canvas.toDataURL(outputFormat);
                callback(dataURL);
                canvas = null; 
            };
            img.src = url;
        }
    }

    componentDidMount = () => {
        bsCustomFileInput.init()
        this.props.despatchAction(ACTION_TYPES.boxElements, {"boxElementsArray": {}})
        this.props.despatchAction(ACTION_TYPES.selectedFielddataHandler, {"selectedFielddata" : {}})

        const params = QS.parse();

        if (QS.validParams(params)) {
            if ( params.accountId && params.sessionId && params.token ) {
                this.getImageUrlFromS3( params )
                this.templateCallback(params)
            } else {
                this.props.ClearLocalStorage()
            } 
        } else {
            alert('No parameters present...')
            this.offlineTemplateCallback()
        }
    }

    render() {
        let selectedS3File = this.props.selectedS3File
        let documentSide = this.props.documentSide
        let validToDisplay

        if ( documentSide ) {
            if ( documentSide.recto && selectedS3File.verso ) {
                validToDisplay = true
            } else if ( documentSide.recto && !documentSide.verso) {
                validToDisplay = true;
            } else {
                validToDisplay = false
            }
        }

        if (this.props.selectedFile || this.props.jsonTemplate || validToDisplay) {
            return(
                <AfterStart 
                    ClearLocalStorage = {this.props.ClearLocalStorage}
                    ActiveSide = { this.props.activeSide }
                />
            )
        } else {
            return (
                <Container fluid>
                    {/* <GetStarted /> */}
                    <Row>
                        <Col>
                            <LoadingSpinner 
                                Message="Loading SignedURL..."
                            />
                        </Col>
                    </Row>
                </Container>
            )
        }
    }
}

const mapStateToProps = state => { return {
    selectedFile: state.selectedFile, 
    jsonTemplate: state.jsonTemplate,
    selectedS3File: state.selectedS3File,
    activeSide: state.activeSide,
    documentSide: state.documentSide,
    params: state.params
} };
export default connect(mapStateToProps, { despatchAction })(LandingPage)