import React, { Component } from 'react';
import {Form} from 'react-bootstrap';

import { connect } from 'react-redux';
import { despatchAction, ACTION_TYPES } from '../../redux/actions';

class TempImageDownloader extends Component {

    imageFileNameHandler = (event) => {
        this.props.despatchAction(ACTION_TYPES.tempImageFileName, {"newTempImageFileName": event.target.value})
        this.props.despatchAction(ACTION_TYPES.templateNameHandler, {"templateName": event.target.value})
    }

    render() {
        
        return (
            <div>
                <Form>
                    <Form.Group controlId="temp-image-downloader-form">
                        <Form.Label>Enter New TemplateId</Form.Label>
                        <Form.Control type="text" placeholder="append '_verso' if you intend on uploading the back" onChange={this.imageFileNameHandler} />
                    </Form.Group>
                    <Form.Group controlId="file-extension-check">
                        <Form.Label>Select Image Side:</Form.Label>
                        <div>
                            <Form.Check id="front" name="sides" type="radio" label="Front" onChange={() => this.props.FileExtensionChecker('front')} />
                            <Form.Check id="back" name="sides" type="radio" label="Back" onChange={() => this.props.FileExtensionChecker('back')} />
                        </div>
                    </Form.Group>
                </Form>
            </div>
        )
    }
}

const mapStateToProps = state => { return {} };
export default connect(mapStateToProps, { despatchAction })(TempImageDownloader)