import React, { Component } from 'react';
import {Form, Row, Col, Button, Dropdown} from 'react-bootstrap';

import { connect } from 'react-redux';
import { despatchAction, ACTION_TYPES } from '../../redux/actions';

import Interact from '../../lib/lem-interact';

class NewRoiForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            human: [],
            withoutHuman: [],
            metadataCheck: [],
            preset: 0
        }
    }

    enterOwnBoxNameHandler = (event) => {
        if (event.target.checked) {
            this.props.despatchAction(ACTION_TYPES.enterOwnBoxName, {"enterOwnBoxName": true})
        } else if (!event.target.checked) {
            this.props.despatchAction(ACTION_TYPES.enterOwnBoxName, {"enterOwnBoxName": false})
        }
    }

    boxNameHandler = (event) => {
        let fielddata = this.props.fielddata;
        fielddata.map( data => {
            if ( data.name === event.target.value) {
                this.props.despatchAction(ACTION_TYPES.boxType, {"boxType": parseInt(data.type)})
            }
            return 'completed'
        })

        this.props.despatchAction(ACTION_TYPES.boxName, {"boxName": event.target.value})
    }

    boxTypeHandler = (event) => {
        this.props.despatchAction(ACTION_TYPES.boxType, {"boxType": parseInt(event.target.value)})
    }

    enterOwnMetadatHandler = (event) => {
        if (event.target.checked) {
            this.props.despatchAction(ACTION_TYPES.enterOwnMetadata, {"enterOwnMetadata": true})
        } else if (!event.target.checked) {
            this.props.despatchAction(ACTION_TYPES.enterOwnMetadata, {"enterOwnMetadata": false})
        }
    }

    metadataCheckHandler = (event, index, preset) => {
        let metadataCheckArr = this.state.metadataCheck;
        let metadata
        if ( preset ) {
            metadata = this.state.withoutHuman[preset];
        } else {
            metadata = this.state.withoutHuman[this.state.preset];
        }

        let metaArr = [];
        Object.entries(metadata).map(entry => {
            return metaArr.push(entry)
        })

        console.log('INDEX: ', index)

        if (event === 'checked' || event.target.checked) {
            if ( metadataCheckArr[index] ) {
                metadataCheckArr[index].check = true
            } else {
                metadataCheckArr.push({name: metaArr[index][0], index, check: true, metadata: {[metaArr[index][0]]: metaArr[index][1]}})
            }
            console.log('META_ARR_ADD: ', metadataCheckArr)
            this.setState({
                metadataCheck: metadataCheckArr
            })
        } else if (!event.target.checked || event === 'unchecked') {
            metadataCheckArr[index].check = false

            console.log('META_ARR_REMOVE: ', metadataCheckArr)
            this.setState({
                metadataCheck: metadataCheckArr
            })
        }
    }

    metadataInputHandler = (event, index) => {
        let metadataCheckArr = this.state.metadataCheck;
        metadataCheckArr.map((data, dataIndex) => {
            let name = data.name
            if (data.index === index) {
                metadataCheckArr[dataIndex].metadata[name] = event.target.value
                this.setState({
                    metadataCheck: metadataCheckArr
                })
            } 
            return 'completed'
        })
    }

    metadataPresetSelectHandler = (index) => {
        let checks = document.getElementsByClassName('metadata-checks')

        console.log('SELCTED PReset: ', index)

        if ( checks ) {
            this.setState({
                metadataCheck: [],
                preset: index
            }, () => {
                for ( let i = 0; i < checks.length; i++ ) {
                    // console.log('CHECK: ', checks[i], i )
                    let check = document.getElementById(`metaDataCheck-${i}`)
                    console.log('CHECK: ', check)
    
                    check.checked = true
    
                    this.metadataCheckHandler('checked', i)
                }
            })
        }
    }

    addBoxesHandler = () => {
        //----- Add box via Lib Module ------
        let canvas = document.getElementById('Imageviewer');
        let boxName = this.props.boxName;
        let boxType = this.props.boxType;
        let activeSide = this.props.activeSide;
        let metadataCheck = this.state.metadataCheck;
        let fielddata = this.props.Fielddata;
        let img = this.props.Image;
        let imgElement = this.props.imgElement
        let widthScaleFactor = img.imgWidth / canvas.clientWidth;
        let heightScaleFactor = img.imgHeight / canvas.clientHeight;
        var boxElements = this.props.boxElementsArray // {}
        let roiData = {
            boxName,
            type: boxType,
            metadataCheck,
            widthScaleFactor,
            heightScaleFactor,
        }

        console.log('WIDTH SF: ', widthScaleFactor)
        console.log('HEIGHT SF: ', heightScaleFactor)

        Interact.newROI(canvas, roiData, this.props.boxMovedCallback, imgElement)
        .then(response => {
            boxElements[boxName] = response
            this.props.despatchAction(ACTION_TYPES.boxElementsHandler, {"boxElementsArray": Object.assign({}, boxElements)})
            this.props.updateFielddataCallback(fielddata, boxName)
            this.props.despatchAction(ACTION_TYPES.boxName, {"boxName": ''})
        })
        .catch(err => {
            console.log(err)
        })
        this.props.closeModal()
    }

    componentDidMount = () => {
        let metadata = this.props.metadata;
        let human = this.state.human
        let withoutHuman = this.state.withoutHuman

        metadata.map(entry => {
            human.push({human: entry.human})
            withoutHuman.push({regex: entry.regex, dateFormat: entry.dateFormat, addCentry: entry.addCentry, dayPos: entry.dayPos, monthPos: entry.monthPos, yearPos: entry.yearPos})
        })

        this.setState({
            human,
            withoutHuman
        })
    }

    render() {
        var fielddata = this.props.Fielddata;
        let metadata = this.state.withoutHuman[this.state.preset];
        let metdataDropdown = this.state.human
        let metadataCheck = this.state.metadataCheck
        let fieldtypes = this.props.fieldtypes;
        let boxName = this.props.boxName
        let addRoiButton
        
        if ( !boxName ) {
            addRoiButton = <Button variant="primary" disabled >Add ROI</Button>
        } else if ( boxName ) {
            addRoiButton = <Button variant="primary" onClick={this.addBoxesHandler}>Add ROI</Button>
        }

        return (
            <div>
                <Form>
                    <Form.Group controlId="boxName">
                        <Form.Check id="enterOwnBoxNameCheck" type="checkbox" label="Enter Own ROI Name?" onChange={this.enterOwnBoxNameHandler} />
                        {!this.props.enterOwnBoxName ? 
                            <div>
                                <Form.Label>Please Select ROI Name</Form.Label>
                                <Form.Control as="select" custom onChange={this.boxNameHandler}>
                                    <option>ROI Name</option>
                                    {fielddata.sort((a, b) => {
                                            return b.name < a.name ? 1
                                            : b.name > a.name ? -1
                                            : 0;
                                        }).map((field, index) => 
                                        <option key={index}>{field.name}</option>
                                    )}
                                </Form.Control>
                            </div>
                        : 
                            <div>
                                <Form.Label>Enter ROI Name</Form.Label>
                                <Form.Control type="text" placeholder="ROI Name (lowercase and underscore as space)" onChange={this.boxNameHandler} />

                                <Form.Label>Enter ROI Type</Form.Label>
                                {/* <Form.Control type="number" placeholder="Box Type (numbers only)" onChange={this.boxTypeHandler} /> */}
                                <Form.Control as="select" custom onChange={this.boxTypeHandler}>
                                    <option>ROI Type</option>
                                    {fieldtypes.map((field, index) => 
                                        <option key={index}>{field.value} - {field.type}</option>
                                    )}
                                </Form.Control>
                            </div>
                        }

                        <Form.Check id="enterOwnMetaDataCheck" type="checkbox" label="Enter Own Metadata?" onChange={this.enterOwnMetadatHandler} />

                        {this.props.enterOwnMetadata ? 
                            <div>
                                <Form.Label>Please Select Metadata field you would like to add</Form.Label>

                                <Row>
                                    <Col>

                                    </Col>
                                    <Col>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" style={{width: '100%'}}>
                                                Presets
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                { metdataDropdown.map((meta, index) => 
                                                    <Dropdown.Item key={index}>
                                                            <Row style={{fontSize: '10px'}} onClick={() => this.metadataPresetSelectHandler(index)}>
                                                                <Col>
                                                                    { meta['human'] }
                                                                </Col>
                                                            </Row>
                                                    </Dropdown.Item>
                                                ) }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                </Row>

                                {Object.entries(metadata).map((entry, index) => 
                                    // console.log('ENTRY: ', entry[0])
                                    // entry[0] !== 'human' ?

                                    //  entry[0] !== 'human' ?
                                        <div key={index} style={{marginBottom: "5px"}} id={`metaDataItem-${index}`}>
                                            <Row>
                                                <Col>
                                                    <Form.Check id={`metaDataCheck-${index}`} className='metadata-checks' type="checkbox" label={entry[0]} onChange={(event) => this.metadataCheckHandler(event, index)} />
                                                </Col>
                                                <Col>
                                                    {metadataCheck.map((check, metaCheck) =>
                                                        check.index === index ?
                                                            check.check ?
                                                                <Form.Control key={index} type="text" value={metadataCheck[metaCheck].metadata[metadataCheck[metaCheck].name]} onChange={(event) => this.metadataInputHandler(event, index)} />
                                                            :
                                                            ''
                                                        :
                                                        ''
                                                    )}
                                                </Col>
                                            </Row> 
                                        </div>
                                        // :
                                        // <div key={index}></div>
                                    
                                        
                                    // :
                                    //     ''
                                // }
                                )}
                            </div>
                        : 
                            ''
                        }

                        <div className='btn-container'>
                            { addRoiButton }
                        </div>
                        <div className='btn-container'>
                            <Button variant="secondary" onClick={() => this.props.closeModal()}>Close</Button>
                        </div>
                    </Form.Group>
                </Form>
            </div>
        )
                
    }
}

const mapStateToProps = state => { return {
    fielddata: state.fielddata, 
    metadata: state.metadata,
    fieldtypes: state.fieldtypes,
    enterOwnBoxName: state.enterOwnBoxName, 
    enterOwnMetadata: state.enterOwnMetadata, 
    displayAddNewRoiModal: state.displayAddNewRoiModal, 
    boxName: state.boxName,
    boxType: state.boxType,
    selectedFile: state.selectedFile,
    boxElementsArray: state.boxElementsArray,
    selectedFielddata: state.selectedFielddata,
    imgElement: state.imgElement
} };
export default connect(mapStateToProps, { despatchAction })(NewRoiForm)