import React, { Component } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';

import { connect } from 'react-redux';
import { despatchAction } from '../../redux/actions';

import Imageviewer from '../Imageviewer';
import AddNewRoiModal from '../Modal/AddNewRoiModal';
import DeleteButton from '../Buttons/DeleteButton';

import './index.css'

class Maincontainer extends Component {
    render() {
        let selectedFile = this.props.selectedFile
        let dataURL = selectedFile.dataURL;
        let activeTemplate = this.props.activeTemplate;
        console.log('FIELDDATA: ', this.props.fielddata)

        return (
            <div style={{ width: '100%', marginTop: '10px'}}>
                <Imageviewer 
                    selectedFile={dataURL}
                />

                <Container className="active-template-container">
                    
                    <Row>
                        <Col>
                            <p className="template-item">Type: {activeTemplate ? activeTemplate.type : ''}</p>
                        </Col>
                        <Col>
                            <p className="template-item">ISO Digit: {activeTemplate ? activeTemplate.country : ''}</p>
                        </Col>
                        <Col>
                            <p className="template-item">Temp: {activeTemplate ? activeTemplate.template : ''}</p>
                        </Col>
                    </Row>
                    
                </Container>
                
                <div className='btn-container'>
                    <Button id='add-btn' className='settings-btn' onClick={this.props.showAddNewModalCallback} >Add New ROI</Button>
                </div>
                <div className='btn-container'>
                    <DeleteButton 
                        deleteRoiCallback = { this.props.deleteRoiCallback }
                    />
                </div>
                <AddNewRoiModal 
                    boxMovedCallback = {this.props.boxMovedCallback}
                    updateFielddataCallback = {this.props.updateFielddataCallback}
                    Fielddata = {this.props.fielddata}
                    Image = { this.props.selectedFile }
                />
            </div>
        )
    }
}

const mapStateToProps = state => { return {
    // selectedFile: state.selectedFile, 
    activeTemplate: state.activeTemplate,
    fielddata: state.fielddata,
    activeSide: state.activeSide
} };
export default connect(mapStateToProps, { despatchAction })(Maincontainer)