import React from 'react';
import ReactDOM from 'react-dom';
// import { Provider } from './components/Context';
import { Provider } from 'react-redux';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './components/App';

import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './redux/reducers';
import { PersistGate } from 'redux-persist/integration/react';

const store = createStore(persistReducer({ key: 'lem', storage }, rootReducer));
const persistor = persistStore(store);

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);
